<template>
  <div class="search-in">
    <div class="close-button" @click="onCancele">
      <i class="el-icon-circle-close"></i>
    </div>
    <div class="search-in-title">船舶轨迹对比查询</div>
    <el-form ref="form">
      <el-form-item v-for="(item,index) in itemList" label="船舶名称" class="form-item" :key="index" required>

        <div style="display: flex; justify-content: center;">
          <!-- <el-select
            clearable
            v-model="itemList[index]"
            :style="{'width': '250px','flex-shrink': 0, 'margin-left': (index == itemList.length-1) && index == 2 ? '35px': '0px'}"
            filterable
            placeholder="请选择"
            @change="querychange"
          >
            <el-option
              v-for="item in optionsForBoatFilter"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-select
            clearable
            v-model="itemList[index]"
            :style="{'width': '250px','flex-shrink': 0, 'margin-left': (index == itemList.length-1) && index == 2 ? '35px': '0px'}"
            filterable
            remote
            placeholder="请输入2个以上的关键字检索"
            :remote-method="(keyWord)=>remoteMethod(keyWord, index)"
            :loading="loadingBoat"
            @change="querychange"
          >
            <el-option
              v-for="item in boatOptions[index]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <div  style="display: inline-flex; justify-content: center;align-items: center; margin-left: 10px; height: 25px; width: 25px;"></div>
 -->

          
            <div style="display: inline-flex; justify-content: center;align-items: center; " v-if="(index == itemList.length-1) && index < 3" >
              
                <el-button @click="addShip" type="success" icon="el-icon-circle-plus" size="mini" circle style="font-size: 22px; line-height: 25px; height: 25px; width: 25px; padding: 0px; margin-left: 10px;"></el-button>
                <el-button @click="cutShip(index)" v-show="(index == itemList.length-1) && index == 2" type="info" icon="el-icon-remove" size="mini" circle style=" color: #ffffff ; font-size: 22px; line-height: 25px; height: 25px; width: 25px; padding: 0px; margin-left: 10px;"></el-button>
              </div>
            <div v-else-if="itemList.length > 2 " style="display: inline-flex; justify-content: center;align-items: center; ">
              <el-button @click="cutShip(index)" type="info" icon="el-icon-remove" size="mini" circle style=" color: #ffffff ; font-size: 22px; line-height: 25px; height: 25px; width: 25px; padding: 0px; margin-left: 10px;"></el-button>
            </div>
            <div v-else style="display: inline-flex; justify-content: center;align-items: center; margin-left: 10px; height: 25px; width: 25px;">

            </div>
        </div>
          
          
          
          
      </el-form-item>

      <el-form-item style="margin-left: 0px" label="选择时间" class="form-item" required>
          <el-date-picker
            v-model="timeArr"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            value-format="yyyyMMdd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            style="width: 250px"
          >
          </el-date-picker>
        </el-form-item>

      <el-form-item size="large" style="text-align: center; margin-top: 30px">
        <el-button id="btn-confirm" type="primary" @click="onSubmit" style="width: 100px"
          >查询</el-button
        >
        <el-button id="btn-cancel" style="margin-left: 30px; width: 100px" @click="onCancele"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import shipList from '../utils/shipList';

import {getBoatListByKeyWord,} from "../utils/api/index.js";

export default {
  name: "portSelector",
  props: ['shipList', 'optionsForBoatFilter'],
  data() {
    return {
      itemList: ["",""],
      boatOptions: [[],[],[],[]],
      timeArr: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selectShipList: [],
      loadingBoat: false,
    };
  },
  mounted() {
    let that = this;
    setTimeout(function(){
      that.initData();
    }, 200)
  },
  methods: {
    initData() {
      if(this.shipList == null || this.shipList.length == 0){
      this.itemList = ["",""]
    } else if(this.shipList.length == 1){
      this.itemList = [this.shipList[0], ""];
    } else{
      this.itemList = this.shipList;
    }
    },
    unique(arr, uniId) {
      let hash = {};
      return arr.reduce((accum, item) => {
        hash[item[uniId]] ? "" : (hash[item[uniId]] = true && accum.push(item));
        return accum;
      }, []);
    },
    remoteMethod(keyWord, index){
      console.log(keyWord, index);
      keyWord = keyWord.split(' ').join('');
      if (keyWord.length > 1) {
          this.loadingBoat = true;
          getBoatListByKeyWord({keyWord})
            .then(res => {
              if (res.data.resultCode == 0) {
                
                

               
        
        //console.log("neo4j 查询结果", JSON.stringify(resultData));

                let nodes = [];
                let resultData = res.data.resultData;
                for (let i = 0; i < resultData.length; i++) {
                  let nodeH = {};
                  for (let nodeKeyName in resultData[i]) {
                    // 将每个船舶信息从json解析出来赋值给nodeH
                    nodeH[nodeKeyName] = resultData[i][nodeKeyName];
                  }
                  //console.log(nodeH)
                  if (nodes.indexOf(nodeH) == -1) {
                    nodes.push(nodeH);
                  }
                }
                nodes = this.unique(nodes, "name");
                let tempOptions = [];
                nodes.forEach((obj) => {
                    let name = obj.name;
                    tempOptions.push({
                      value: name,
                      label:
                        "英文名：" +
                        name +
                        (typeof obj.CNname == "string" && obj.CNname != ""
                          ? "  中文名：" + obj.CNname
                          : "") +
                        (typeof obj.cnname == "string" && obj.cnname != ""
                          ? "  中文名：" + obj.cnname
                          : "") +
                        (parseInt(obj.mmsi) > 0 ? "  mmsi: " + obj.mmsi : "  mmsi: " + obj.mmsi) +
                        (parseInt(obj.imo) > 0 ? "  imo: " + obj.imo : "") +
                        (typeof obj.hull_number == "string" && obj.hull_number != ""
                          ? "  舷号：" + obj.hull_number
                          : ""),
                      obj,
                    });
                    tempOptions = this.unique(tempOptions, "value");

                })
                this.boatOptions[index] = tempOptions;
              } else {
                this.boatOptions[index] = [];
              }
              this.loadingBoat = false;
            })
            // .catch(err => {
            //   console.err(err);
            // })
        } else {
          this.boatOptions[index] = [];
          this.loadingBoat = false;
        }
    },
    onSubmit() {
      console.log("onSubmit")
      if(this.itemList.length < 2) {
        this.$message({
          message: "请选择要对比的船舶！",
          type: "error",
          duration: 1600,
        });
        return;
      }else{
        const set = new Set(this.itemList.map(JSON.stringify));
        if(set.size !== this.itemList.length){
          this.$message({
            message: "请选择不同的船舶选项！",
            type: "error",
            duration: 1600,
          });
          return;
        }
      }
      for(let i = 0; i < this.itemList.length; i++){
        if(this.itemList[i] == ""){
          this.$message({
            message: "请选择要对比的船舶！",
            type: "error",
            duration: 1600,
          });
          return;
        }
      }

      if (this.timeArr == null || this.timeArr == "" || this.timeArr == []) {
        this.$message({
          message: "时间不能为空！",
          type: "error",
          duration: 1200,
        });
        return;
      }

      console.log(this.itemList);
      console.log(this.timeArr);
      
      this.$emit("getTrackComData", this.itemList, this.timeArr)


    },
    onCancele() {
      this.$emit("onTrackComCancel")
    },
    querychange(value,l){
      console.log(value)
      console.log(l)
      console.log(this.itemList)
    },
    addShip(){
      this.itemList.push("");
    },
    cutShip(index){
      this.itemList.splice(index, 1);
    }
  },
};
</script>

<style scoped lang="less">
.search-in {
  width: 390px !important; 
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray, 2px 0 3px -1px gray;

  color: white;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 30px;
  padding-top: 40px;

  #btn-confirm {
    background-color: #165184;
    border-color: #165184;
  }

  #btn-cancel {
    background-color: #3c3d3f;
    border-color: #3c3d3f;
    color: white;
  }

  .search-in-title {
    text-align: center;
    font-size: 20px;
    //color: white;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  /deep/ .el-form-item__label {
    font-size: 12pt;
    color: #7cd8da;
  }

  .form-item {
    margin-top: 30px;
    width: 370px;
  }
  .close-button {
      position: absolute;
      top: 3px;
      right: 5px;
      z-index: 9277;
      font-size: 25px;
      color: #fff;
    }

}
</style>
